// все актуально для переключалки языка в хедере
app-language-switcher {
  .p-dropdown--custom {
    border: none;
    background: rgb(255 255 255 / 20%);
    border-radius: 0.5rem;
    .p-dropdown-trigger {
      display: none;
    }
    .p-dropdown-label {
      color: var(--color-white);
      padding: 0.5rem 1rem !important;
      border-radius: 0 !important;
    }
    .p-overlay{
      right: 0;
      left: unset !important;
    }
  }
}
