.p-card {
  border-radius: var(--border-radius-default) !important;
  background: var(--color-white);
  box-shadow: none !important;
  border: 1px solid var(--color-black-overlay-7);
  padding: 1.25rem;
  width: 100%;
  .p-card-content, .p-card-body {
    padding: 0 !important;
  }
}
