// все актуально
button:active,
button:focus {
  outline: none !important;
}
button::-moz-focus-inner {
  border: 0 !important;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

button{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0;
  gap: 0.65rem;

  border: 1px solid transparent;
  color: var(--color-black-12);
  border-radius: var(--border-radius-default) !important;
  background-color: transparent;

  -webkit-user-select: none;
  user-select: none;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  position: relative;
  &.loading{
    cursor: default;
    &:before{
      content: "\e926";
      position: absolute;
      color: var(--color-primary-100);
      animation: fa-spin 2s infinite linear;
      font-family: 'primeicons', serif;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
    }

  @keyframes fa-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  }
}
.btn-primary{
  color: var(--color-white);
  border-color: var(--color-primary-100);
  background-color: var(--color-primary-100);
  &:hover:not(:disabled){
    color: var(--color-primary-100);
    background-color: transparent;
    border-color: var(--color-primary-100);
  }
  &:disabled{
    color: var(--color-white);
    border-color: var(--color-primary-50);
    background-color: var(--color-primary-50);
  }
  &-outline{
    color: var(--color-primary-100);
    background-color: transparent;
    border-color: var(--color-primary-100);
    &:hover:not(:disabled){
      color: var(--color-white);
      border-color: var(--color-primary-100);
      background-color: var(--color-primary-100);
    }
    &:disabled{
      color: var(--color-primary-50);
      background-color: transparent;
      border-color: var(--color-primary-50);
    }
  }
  &-noBordrer{
    color: var(--color-primary-100);
    background-color: transparent;
    border-color: transparent;
    padding:0;

    &:hover:not(:disabled){
      color: var(--color-primary-50);
      background-color: transparent;
      border-color: transparent;
    }
  }
}
.btn-blue{
  color: var(--color-white);
  border-color: var(--color-blue-100);
  background-color: var(--color-blue-100);
  &:hover:not(:disabled){
    color: var(--color-blue-100);
    background-color: transparent;
    border-color: var(--color-blue-100);
  }
  &-outline{
    color: var(--color-blue-100);
    background-color: transparent;
    border-color: var(--color-blue-100);
    &:hover:not(:disabled){
      color: var(--color-white);
      border-color: var(--color-blue-100);
      background-color: var(--color-blue-100);
    }
  }
  &-noBordrer{
    color: var(--color-blue-100);
    background-color: transparent;
    border-color: transparent;
    padding:0;

    &:hover:not(:disabled){
      color: var(--color-blue-50);
      background-color: transparent;
      border-color: transparent;
    }
  }
}
.btn-gray{
  color: var(--color-white);
  border-color: var(--color-white-overlay-50);
  background-color: var(--color-white-overlay-50);
  &:hover:not(:disabled){
    color: var(--color-white);
    background-color: transparent;
    border-color: var(--color-white-overlay-50);
  }
  &-outline{
    color: var(--color-white);
    background-color: transparent;
    border-color: var(--color-white-overlay-50);
    &:hover:not(:disabled){
      color: var(--color-white);
      border-color: var(--color-white-overlay-50);
      background-color: var(--color-white-overlay-50);
    }
  }
}
