.p-radioButton {
  height: 20px !important;
  width: 20px !important;
  .p-radiobutton-box {
    border: 1px solid var(--color-black-12) !important;
    height: 20px !important;
    width: 20px !important;
  }
  .p-radiobutton-icon {
    background-color: var(--color-blue-100) !important;
    visibility: hidden !important;
  }
  .p-highlight {
    border-width: 6px !important;
    border-color: var(--color-blue-100) !important;
    background: unset !important;
  }
}
.p-radiobuttonLabel {
  @media screen and (max-width: 370px) {
    margin-right: 0.5rem;
  }
}
