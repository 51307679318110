 .p-toast-message{
   border-radius: var(--border-radius-default) !important;
   border-width: 2px !important;

   color: var(--black) !important;
   .p-toast-message-icon{
     display: none;
   }
   &-error{
     background: var(--color-primary-12) !important;
     border: solid var(--color-primary-50) !important;
   }
   &-success{
     background: var(--color-green-12) !important;
     border: solid var(--color-green-50) !important;
   }
    &-warn{
      background: var(--color-yellow-7) !important;
      border: solid var(--color-yellow-50) !important;
    }
 }
