.custom-placeholder {
  &::placeholder {
    color: var(--color-black-12);
  }
}

.form input::placeholder {
  color: var(--color-black-12);
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
.form input:not([type]),
.form input[type="date"],
.form input[type="datetime-local"],
.form input[type="email"],
.form input[type="file"],
.form input[type="number"],
.form input[type="password"],
.form input[type="search"],
.form input[type="tel"],
.form input[type="text"],
.form input[type="time"],
.form input[type="url"] {
  border: 1px solid var(--color-black-12);
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  line-height: var(--line-height);
  padding: 2rem 1rem 0.5rem 1rem;
  font-size: 1rem;
  background: var(--color-white);
  border-radius: 8px;

  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: 0 0 0 0 transparent inset;

  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}
// обязательная обертка для стандартного инпута
.inputfield {
  position: relative;
  width: 100%;
  min-height: 3.25rem;

  .label {
    position: absolute;
    background-color: transparent;
    top: 0.5rem;
    left: 1rem;
    z-index: 10;
    font-size: 0.8rem;

  }
  .label.disabled{
    color: var(--color-black-12);
  }
  input{
    width: 100%;
  }
  input.ng-invalid.ng-touched {
    border-color: var(--color-primary-100);
  }
}

/* ------------ Prime NG ------------ */

.p-inputtext {
  padding: 2rem 1rem 0.5rem 1rem !important;
  border-radius: var(--border-radius-default) !important;
}
p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: var(--color-primary-100) !important;
}
p-radioButton {
  margin-right: 0.5rem;
}

.field{
  //padding: 0.25rem !important;
}

  /* ----------------------- */

.input input[type="text"]:focus,
.form input[type="text"]:focus {
  -webkit-text-size-adjust: 100% !important;
}
.custom-control{
  @media  (max-width: ($md)) {
    padding: 0.5rem !important;
    min-height: 32px;
  }
}

/// radio------------------------------

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 19px;
  height: 19px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: transparent;
  border: 6px solid var(--color-blue-100);
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.additional-fields__passenger-row,
.additional-fields__passenger-control-wrap {
  display: flex;
  align-items: center;
}
.additional-fields__passenger-control-wrap {
  justify-content: flex-end;
}
.additional-fields__passenger-row {
  width: 100%;
  justify-content: space-between;
  //margin: 0.5rem;
}
.additional-fields__passenger-control {
  background: var(--color-blue-100);
  width: 20px;
  height: 20px;
  border-radius: 6px;
  color: var(--color-white);
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  svg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
  }
}
.additional-fields__passenger-control.--is-disabled {
  background-color: var(--color-black-overlay-7);
  cursor: default;
}
.additional-fields__passenger-title {
  margin-right: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.additional-fields__passenger-value {
  display: block;
  //width: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin: 0 0.7rem;
}
app-passengers-select-card{
  @media  (max-width: ($md)) {
    padding: 0.5rem 1rem;

  }
}
