// все актуально для фака на главной
app-faq {
  .p-accordion,
  .p-accordion .p-accordion-header .p-accordion-header-link {
    font-family: $font-family-basic;
    font-size: $font-size;
    line-height: $line-height !important;
    font-weight: normal !important;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    background-color: var(--color-white);
    padding: 1rem;
    border-radius: var(--border-radius-default);
    // border-color: transparent;
    border: 1px solid var(--color-black-overlay-7);
    //box-shadow: 0px 1px 2px #dde0e3;
    margin-bottom: 1rem;
    color: var(--color-black-50);
  }
  .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    border-color: transparent;
    background: var(--color-black-overlay-7) !important;
  }
  .p-accordion
    .p-accordion-header:not(.p-disabled)
    .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
  }
  .p-accordion .p-accordion-header.p-highlight .p-accordion-header-link {
    border-color: transparent;
    background: var(--color-white) !important;
    color: var(--color-black) !important;
  }
  .p-accordion .p-accordion-content {
    border: none;
  }
  .p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header
    .p-accordion-header-link {
    border-top: 1px solid var(--color-black-overlay-7);
  }
  .p-accordion-header-link {
    border-radius: var(--border-radius-default) !important;
  }
  .p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
    .p-accordion-header-link,
  .p-accordion
    p-accordiontab:not(:first-child)
    .p-accordion-header:not(.p-disabled).p-highlight:hover
    .p-accordion-header-link {
    border-top: 1px solid transparent;

  }
  .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: var(--color-black-overlay-7);
    border-color: var(--color-black-overlay-7);
    color: var(--color-black);
  }
  .p-accordion-header-text{
    color: var(--color-black) !important;
  }
}
