.p-autocomplete-panel {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(128, 128, 128, 0.246);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(121, 121, 121, 0.424);
    border-radius: 20px;
    // border: 3px solid orange;
  }
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: rgba(121, 121, 121, 0.424) rgba(128, 128, 128, 0.246); /* scroll thumb and track */
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group,
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  min-height: 2.5rem;
}
