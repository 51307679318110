app-phone-input {
  .p-dropdown--custom {
    border: none;
    .p-dropdown-trigger {
      //display: none;
      width: 1.5rem!important;
      .p-dropdown-trigger-icon{
        font-size: 0.75rem;
      }
    }
    .p-dropdown-label {
      padding: 0 !important;
    }
    .p-dropdown-panel{
      top: 1.5rem !important;
    }
  }
}
app-phone-input.ng-invalid.ng-touched{
  input{
    border-color: var(--color-primary-100);
  }
}
