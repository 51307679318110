

/* --------------- Typographics -------------- */

$font-size: 1rem;
$line-height: 1.4;

$font-family-basic: var(--font-family-sans-serif);
$font-family-header: var(--font-family-sans-serif);

/* --------------- Breakpoints -------------- */
$screen-xxs: 320px;
$screen-s: 568px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-xl: 1920px;

// от праймсетки размеры
$sm: 576px ;
$md: 768px ;
$lg: 992px ;
$xl: 1200px;

$screen-mobile: 360px;
$screen-tablet: 768px;
$screen-desktop: 1440px;

$border-radius: 0.5rem;


:root{
  --max-container-width: 1150px;
  /* ---------------------------------Colors-------------------------------------------------*/

  --color-white: #FFF;
  --color-white-overlay-50: rgba(255, 255, 255, 0.5);

  --color-black-contrast: #292B3A;
  --color-black-50: #808080;
  --color-black-12: #C9C9C9;
  --color-black: #000;

  --color-background-color: #FCFCFD;

  --color-primary-100: #C50018;
  --color-primary-50: #E1808B;
  --color-primary-12: #F8E0E3;
  --color-primary-7: #FBEDF2;


  --color-yellow-500: #C58B15;
  --color-yellow-400: #D09A1C;
  --color-yellow-300: #DBA923;
  --color-yellow-200: #E6B82A;
  --color-yellow-100: #F1C731;
  --color-yellow-50: #F8E398;
  --color-yellow-7: #FEFBF1;

  --color-green-100: #0BB884;
  --color-green-50: #85DBC1;
  --color-green-12: #E2F6F0;
  --color-green-7: #EEFAF6;

  --color-blue-100: #484CB9;
  --color-blue-50: #A3A6DC;
  --color-blue-7: #F2F2FA;

  --color-gray-overlay-7: rgba(144, 152, 163, 0.07);
  --color-black-overlay-7: rgba(0, 0, 0, 0.07);

  /* --------------- Other -------------- */

  --line-height: 1.4;
  --font-size: 1rem;

  --border-radius-default: 0.5rem;

  --header-size: 3.5rem;
  --footer-size: 5rem;

  --font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}


