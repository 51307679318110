.work-time {
  //background-color: var(--color-gray-overlay-7);
  //margin-bottom: 2rem;
}
.p-dialog .p-dialog-content {
  border-radius: var(--border-radius-default) !important;
  background: transparent;
  padding: 0 !important;
}
.phone-block,
.socials {
  a {
    text-decoration: none;
    color: var(--color-black);
    &:hover {
      color: var(--color-blue-100);
    }
  }
}
.phone-block,
.email-block,
.req-block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  span {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
.phone-main {
  color: var(--color-white);
  font-size: 1rem;
  text-decoration: none;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  flex-wrap: wrap;
  span:first-of-type {
    margin-right: 0.5rem;
  }
  &:hover {
    color: var(--color-primary-100);
  }
}
.line-height-normal {
  line-height: normal;
}
.white-space-nowrap {
  .p-tooltip-text {
    white-space: nowrap !important;
  }
}
