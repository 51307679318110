@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
html {
  @include fluidFontSize(12px, 16px, 480px, 1920px, 16px);
}
body {
  font-family: $font-family-basic;
  //font-size: $font-size;
  line-height: $line-height;
  //color: $color-base;
  color: var(--black);
}
