// все используется для навигации в лк
app-navigate {
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    @media (min-width: $screen-sm) {
      background-color: var(--color-background-color) !important;
    }
    @media (max-width: $screen-sm) {
      background-color: var(--color-background-color) !important;
    }
    color: var(--color-blue-100);
    border-color: unset !important;

    &:not(.p-disabled):focus {
      box-shadow: unset;
    }
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    width: 50%;
  }
  .p-tabmenu .p-tabmenu-nav {
    background: unset !important;
    border: unset !important;
    border-width: unset !important;
    width: 100%;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    padding: 0.5rem !important;
    background: var(--color-black-12) !important;
    place-content: center;
    border-top-right-radius: var(--border-radius-default);
    border-top-left-radius: var(--border-radius-default);
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: none !important;
  }
}
