.custom-dropdown {
  background: var(--color-white) !important;
  border: 1px solid var(--color-black-12) !important;
  border-radius: var(--border-radius-default) !important;
  height: 100%;
  min-height: 3.25rem;
  align-items: center;
  .p-dropdown-label {
    padding: 0.5rem 1rem !important;
    color: var(--color-black) !important;
  }
  .selected-label{
    position: absolute;
    font-size: 0.8rem;
    padding-top: 0.5rem;
    top: 0;
  }
  .selected-value{
    display: inline-block;
    padding-top: 1rem;
  }
}
.custom-calendar-overlay{
  .p-datepicker:not(.p-datepicker-touch-ui){
    right: 0;
    left: unset !important;
  }
}
.p-inputwrapper.ng-invalid.ng-touched{
  .custom-dropdown{
    border-color: var(--color-primary-100) !important;
  }
  .p-inputtext{
    border-color: var(--color-primary-100) !important;
  }
}
