// все актуально для переключалки языка в хедере
.currency-switcher {
  height: 2rem;
  .p-dropdown--custom {
    border: none;
    background: rgb(255 255 255 / 20%);
    border-radius: 0.5rem;
    height: 100%;
    align-items: center;
    .p-dropdown-trigger {
      display: none;
    }
    .p-dropdown-label {
      color: var(--color-white);
      padding: 0 1rem !important;
      border-radius: 0 !important;
    }
    .p-overlay{
      right: 0;
      left: unset !important;
    }
  }
}
