body {
  margin: 0;
  padding: 0;
}

h1,
h2 {
  font-family: $font-family-header;
  text-align: center;

  //margin-top: 2rem;
  //margin-bottom: 2rem;
  //margin-top: 2rem;
  margin: 0;

  //@media (max-width: ($screen-sm - 1)) {
  //  margin-bottom: 1rem;
  //  margin-top: 1rem;
  //}
}

h1 {
  text-transform: uppercase;
  //font-size: $font-size-h1;
  font-weight: 700;

  color: rgba(176, 173, 222, 0.6);

  @media (max-width: $screen-xxs) {
    //font-size: $font-mobile-h1;
  }
}

h2 {
  //font-size: $font-size-h2;
  font-weight: 500;

  @media (max-width: $screen-xxs) {
    //font-size: $font-mobile-h2;
  }
}

h3,
h4,
h5,
h6 {
  font-family: $font-family-basic;
  font-weight: 500;
  margin: 0;
  //font-size: $font-size-h-other;
}

h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: 1.5rem;

  @media (max-width: ($screen-sm - 1)) {
    margin-bottom: 1rem;
  }
}
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
  //margin-top: 0;
}

textarea {
  overflow: auto; // Удаление верт. скролла в IE.
  resize: vertical; // Правильное изменение размеров
}

svg {
  fill: currentColor;

  a &,
  button & {
    pointer-events: none; // Мы любим Edge, это браузер! (почти)
  }

  &:not(:root) {
    overflow: hidden; // Скрыть выступающее за границы в IE
  }
}

.text-up {
  text-transform: uppercase;
}
.text-low {
  text-transform: lowercase;
}

.small-text {
  font-size: 0.85rem;
}

.big-text {
  font-size: 2rem;
  @media (max-width: ($screen-s - 1)) {
    font-size: 1.5rem;
  }
}

// юзается
.dark-background {
  background-color: var(--color-black-contrast);
  width: 100%;
}
.white-background{
  background-color: var(--color-white);
}
.primary-background {
  background-color: var(--color-black-12);
}
.text-white{
  color: var(--color-white);
}
.extra-space{
  margin-block: 2rem;

  @media (max-width: $screen-s) {
    margin-block: 1.5rem;
  }
}

.icon-text {
  font-size: 2rem;
}

.only-mobile {
  display: none;

  @media (max-width: $screen-s) {
    display: block;
  }
}
.only-mobile {
  display: none;

  @media (max-width: $screen-s) {
    display: block;
  }
}

.container {
  height: 100%;
  padding: 15px 20px;
  &--limited {
    max-width: var(--max-container-width);
    // max-width: 80%;
    width: 100%;
  }
}
.main-container{
  padding-inline: 1rem;
  width: 100%;
  max-width: min(100vw, calc( var(--max-container-width) - 2rem) );
  height: 100%;
  border-radius: 1rem;
  background-color: var(--color-background-color);
}
blockquote {
  position: relative;
  padding: 0.5rem 25px;
  quotes: "\201C""\201D""\2018""\2019";
  font-size: 1.4rem;
  text-align: left;
  &:before {
    font-family: serif;
    color: #8c8c8c;
    content: open-quote;
    font-size: 5rem;
    position: absolute;
    top: -40px;
    left: -40px;
  }
}
blockquote footer {
  text-align: right;
}
blockquote cite {
  color: #666666;
}
.centerposition {
  margin: 0 auto !important;
}

/* Firefox */
html,
.custom-scroll {
  //scrollbar-width: thin;
  //scrollbar-color: var(--color-gray-450) var(--color-theme-600);
}

/* Chrome, Edge and Safari */
html::-webkit-scrollbar,
.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

html::-webkit-scrollbar-track,
.custom-scroll::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: var(--color-black-50);
}

html::-webkit-scrollbar-track:hover,
.custom-scroll::-webkit-scrollbar-track:hover {
  background-color: var(--color-black-50);
}

html::-webkit-scrollbar-track:active,
.custom-scroll::-webkit-scrollbar-track:active {
  background-color: var(--color-black-50);
}

html::-webkit-scrollbar-thumb,
.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--color-black-12);
}


html::-webkit-scrollbar-thumb:hover,
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-black-12);
}

html::-webkit-scrollbar-thumb:active,
.custom-scroll::-webkit-scrollbar-thumb:active {
  background-color: var(--color-black-12);
}

.custom-scroll--hover {
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-black-12);
    }

    &::-webkit-scrollbar-track {
      background-color: var(--color-black-50);
    }
  }
}
